// Home page-specific rules

@import 'helpers/variables';
@import 'helpers/mediaqueries';
@import 'helpers/mixins';

.hero--home {
  background-image: url(../images/bgd-home-sm.jpg);
  background-position: center top;
  padding-top: 90px;


  @include respond-to(0px, $xsmall-max, portrait) {
    padding-top: 40px;
    height: calc(100vh - 25px);
  }

  @include respond-to($small-min, $small-max, portrait) {
    padding-top: 90px;
    height: calc(100vh - 100px);
  }

  @include respond-to($large-min) {
    background-image: url(../images/bgd-home.jpg);
    padding-top: 0px;
    height: calc(100vh - 95px);
  }

  @include respond-to($xlarge-min) {
    background-image: url(../images/bgd-home@2x.jpg);
  }

  h1 {
    @include respond-to(0px, $small-min) {
      font-size: 20px;
    }
  }

  .container {
    max-width: 600px;

    p {
      @include respond-to($large-min) {
        margin: 20px auto;
      }
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
  }

  .hero-content__text {
    transform: translateY(40px);
    opacity: 0;
    animation: heroContent 1s ease .5s forwards;
  }
}

.download-buttons--home {
  @include respond-to($medium-min) {
    margin-top: 30px;
  }
}

.jump-link {
  background-color: $color-background;
  position: absolute;
  z-index: 10;
  bottom: -30px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: transform .3s ease;
  cursor: pointer;

  &:before {
    position: absolute;
    z-index: 5;
    top: 0px;
    left: 0px;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0,0,0,.6);
    content: "";
    transition: opacity .3s ease;
  }

  .caret {
    transition: transform .3s ease;
  }

  &:hover, &:focus {
    transform: translateX(-50%) scale(.98);

    &:before {
      opacity: .5;
    }

    .caret {
      transform: translateY(-2px);
    }
  }
}

.hero-home-phones {
  position: relative;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: auto;
  margin-top: 10px;
  overflow: hidden;
  height: 32vh;

  @include respond-to($small-min) {
    margin-top: 10px;
  }

  @include respond-to($medium-min) {
    height: 60vh;
  }

  @include respond-to($large-min) {
    margin-top: 30px;
    height: 45vh;
  }

  img {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: scale(.95) translateX(-50%) translateY(0);
    transform-origin: 0 50% 0;
    height: 100%;
    opacity: 0;
    animation: heroPhones 2s ease 1.25s forwards;
    backface-visibility: hidden;

    @include respond-to(0px, $medium-max) {
      max-width: 130%;
      max-height: 100%;
      height: auto;
    }
  }
}

@keyframes heroPhones {
  50% {
    transform: scale(1) translateX(-50%) translateY(-30px);
  }
  100% {
    transform: scale(1) translateX(-50%) translateY(-26px);
    opacity: 1;
  }
}

@keyframes heroContent {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// How it Works section
.section--how {
  overflow: hidden;
}

.how-title {
  margin-top: 75px;
  margin-bottom: 75px;
  text-align: center;
  text-transform: uppercase;

  @include respond-to($large-min) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.panel--home {
  background-color: #462a2b;
  color: $color-white;

  @include respond-to($large-min) {
    display: flex;
    margin: 0px auto 160px;
    width: 870px;
    height: 404px;
    box-shadow: 0px 10px 50px rgba(0,0,0,.6);
  }

  .container {
    padding: 0px;

    @include respond-to($large-min) {
      display: flex;
    }

    p {
      margin: 0px;

      @include respond-to($large-min) {
        font-size: 12px;
      }
    }
  }

  .panel__image {
    background-size: cover;
    position: relative;
    height: 404px;

    @include respond-to($large-min) {
      width: 67%;
    }

    img {
      position: relative;
      top: -50px;
      opacity: 0;
      transition: all 1s ease;
      transform: translateX(-80px);
    }
  }

  .panel__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 115px 30px 85px;

    @include respond-to($large-min) {
      padding: 0px 30px;
      width: 33%;
    }

    h2 {
      margin-bottom: 30px;

      @include respond-to(0px, $medium-max) {
        font-size: 32px;
      }
    }

    p {
      @include respond-to(0px, $medium-max) {
        font-size: 18px;
      }
    }
  }
}

.js-animated {
  .panel__image {
    img {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.panel--2 {
    .panel__image {
      img {
        transform: translateX(0);

        @include respond-to($large-min) {
          top: -37px;
        }
      }
    }
  }
}

.panel__left {
  img {
    left: 0px;
    max-width: 90%;

    @include respond-to($medium-min) {
      left: 15px;
    }

    @include respond-to($large-min) {
      left: -30px;
      max-width: none;
    }
  }
}

.panel__right {
  @include respond-to($large-min) {
    text-align: right;
  }

  img {
    right: 80px;
    max-width: 120%;

    @include respond-to($medium-min) {
      right: 20px;
    }

    @include respond-to($large-min) {
      right: -30px;
      max-width: none;
    }
  }
}

.panel--1 {
  background-color: #47235f;

  .panel__image {
    background-image: url(../images/home-panel-1.jpg);

    @include respond-to($large-min) {
      background-image: url(../images/home-panel-1@2x.jpg);
    }
  }
}

.panel--2 {
  .container {
    @include respond-to(0px, $medium-max) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .panel__image {
    background-image: url(../images/home-panel-2.jpg);

    @include respond-to($large-min) {
      background-image: url(../images/home-panel-2@2x.jpg);
    }

    img {
      transform: translateX(80px);
    }
  }
}

.panel--3 {
  background-color: #789ca3;

  .panel__image {
    background-image: url(../images/home-panel-3.jpg);

    @include respond-to($large-min) {
      background-image: url(../images/home-panel-3@2x.jpg);
    }
  }

  .panel__text {
    @include respond-to(0px, $small-max) {
      padding-top: 75px;
    }
  }
}

// Overrides
#main {
  background-image: none;
}

.alert {
  color: $color-primary;

  p {
    margin-bottom: 20px;
  }
}

// Header
.header--home {
  background-color: transparent;
  box-shadow: none;

  .branding {
    transition: transform .3s ease;

    // @include respond-to($large-min) {
    //   transform: translateY(25px);
    // }
  }

  .logo {
    transition: transform .3s ease .1s;

    // @include respond-to($large-min) {
    //   transform: scale(1.36);
    // }
  }

  .logo__outline {
    fill: $color-brand;
  }
}

.js-nav-visible {
  header {
    background-color: $color-background;
    box-shadow: 0px 2px 75px rgba(0,0,0,0.15);
  }

  .branding {
    transform: translateY(0px);
  }

  .logo {
    transform: scale(1);
  }
}

// Video
.video-modal {
  .container {
    max-width: 820px;
  }

  .modal__container {
    background-color: transparent;

    @include respond-to(0px, $medium-max) {
      padding: 0px;
    }
  }

  video {
    width: 100%;
  }

  .modal__close-button {
    @include respond-to(0px, $medium-max) {
      top: -30px;
      right: -30px;
    }
  }
}
